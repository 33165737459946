<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/clientes" />
        </v-col>
      </v-row>
    </v-card-actions>
    <cliente-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import clientes from "@/api/clientes";

export default {
  components: {
    ClienteCampos: () => import("./ClienteCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
    };
  },

  methods: {
    async salvar(cliente) {
      this.carregando = true;
      this.validacaoFormulario = {};

      try {
        await clientes.inserir(cliente);

        this.$router.push("/clientes", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_adicionar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
